import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  HStack,
  useRadioGroup,
  Text,
  Flex,
  useToast,
  Divider,
  Select,
} from "@chakra-ui/react";
import { HomeContext } from "../../../context/HomeContext";
import IconCard from "../../layouts/IconCard";
import Counter from "../../../assets/Counter";
import Picto1 from "./picto4/Picto1.png";
import Picto2 from "./picto4/Picto2.png";
import Picto3 from "./picto4/Picto3.png";
import Picto4 from "./picto4/Picto4.png";
import Picto5 from "./picto4/Picto5.png";
import RadioCard from "../../layouts/RadioCard";

const StepFour = ({ onNext, onBack }) => {
  const [formData, setFormData] = useContext(HomeContext);
  const [isFormValid, setIsFormValid] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const isValid = formData.altitude && formData.numberOfLevels && formData.houseShape;
    setIsFormValid(isValid);
  }, [formData]);

  const handleNextClick = () => {
    if (isFormValid) {
      onNext();
    } else {
      toast({
        title: "Champs manquants",
        description:
          "Veuillez remplir tous les champs obligatoires pour continuer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleNumberOfLevelChange = (value) => {
    console.log(formData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      numberOfLevels: value,
    }));
  };

  const handleRadioChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAltitudeChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const houseShapeOptions = [
    { label: "Carrée", value: "Carrée", icon: Picto3 },
    { label: "Allongée", value: "Allongée", icon: Picto4 },
    { label: "Développée", value: "Développée", icon: Picto5 },
  ];

  const {
    getRootProps: houseShapeGroupProps,
    getRadioProps: houseShapeRadioProps,
  } = useRadioGroup({
    name: "houseShape",
    defaultValue: formData.houseShape,
    onChange: (value) => handleRadioChange("houseShape", value),
  });

  return (
    <Box width="full" px={20}>
      <Box display={"flex"} flexDirection={"row"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
            Architecture de la maison
          </Heading>
          <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
            Décrivez les détails concernant l'architecture de la maison.
          </Text>
        </Box>
        <Button
          ml={"50%"}
          w="120px"
          bgColor={"white"}
          color={"#30C9FF"}
          fontWeight={"600"}
          borderRadius={"8px"}
          padding={"8px, 16px, 8px, 21px"}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        borderColor={"#F4F4F4"}
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Box display={"flex"} mr={'9%'} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Nombr(s) de niveaux <br/> (hors combles si non aménagés) 
              </Heading>
              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
                Choisissez le nombre de niveaux résidentiel
              </Text>
            </Box>{" "}
            <Box display={"flex"} mr={"17%"} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Altitude
              </Heading>

              <Text fontSize="17px" fontWeight="400" color="#8C8C8C" mb={8}>
                Choisissez L’altitude correctes
              </Text>
            </Box>
            <Box display={"flex"} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Silhouette du Domicile
              </Heading>

              <Text fontSize="17px" fontWeight="400" color="#8C8C8C" mb={8}>
                Choisissez le positionnement <br></br> de la maison
              </Text>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"row"}>
            <Box mr={"20px"} flex="1">
              <Box
                display={"flex"}
                w={["325px", "325px", "325px", "385px"]}
                h={"251px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCard icon={Picto1}></IconCard>
                <Text
                  fontSize={["10px", "10px", "11px", "14px"]}
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  NOMBRE(S) DE NIVEAUX HABITABLES (HORS COMBLES)
                </Text>
                <Counter
                  initialValue={0}
                  onCountChange={(value) =>
                    setFormData({ ...formData, numberOfLevels: value })
                  }
                />
              </Box>
            </Box>
            <Divider
              mr={"20px"}
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              color={"#EAEAEA"}
            />
            <Box flex="1">
              <Box
                display={"flex"}
                w={["325px", "325px", "325px", "385px"]}
                mr={"20px"}
                h={"251px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCard icon={Picto1}></IconCard>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  ALTITUDE
                </Text>
                <Select
                  mb={"20px"}
                  name="altitude"
                  width={["313px", "313px", "263px", "363px"]}
                  height={"44px"}
                  fontWeight={"400"}
                  fontSize={"14px"}
                  color={formData.altitude ? "black" : "#A7A7A7"}
                  gap={"302px"}
                  onChange={(e) =>
                    handleAltitudeChange("altitude", e.target.value)
                  }
                  value={formData.altitude}
                >
                  <option value="">Choose</option>
                  <option value="0 à 200m">0 à 200m</option>
                  <option value="201 à 400m">201 à 400m</option>
                  <option value="401 à 600m">401 à 600m</option>
                  <option value="601 à 800m">601 à 800m</option>
                  <option value="801 à 1000m">801 à 1000m</option>
                  <option value="1001 à 1200m">1001 à 1200m</option>
                  <option value="1201 à 1400m">1201 à 1400m</option>
                  <option value="1401 à 1600m">1401 à 1600m</option>
                  <option value="1601 à 1800m">1601 à 1800m</option>
                  <option value="1801 à 2000m">1801 à 2000m</option>
                  <option value="2001 à 2200m">2001 à 2200m</option>
                </Select>
              </Box>
            </Box>
            <Divider
              mr={"20px"}
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              color={"#EAEAEA"}
            />
            <Box flex="1">
              <Stack {...houseShapeGroupProps()} direction="column">
                {houseShapeOptions.map((option) => (
                  <RadioCard
                    key={option.value}
                    {...houseShapeRadioProps({ value: option.value })}
                    icon={option.icon}
                  >
                    {option.label}
                  </RadioCard>
                ))}
              </Stack>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" my="3%">
        <Button
          w="238px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={handleNextClick}
        >
          Confirme les informations
        </Button>
      </Flex>
    </Box>
  );
};

export default StepFour;
