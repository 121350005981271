import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';

const IconCard = ({ icon, label }) => {
  return (
    <Box
    my={'10px'}
    display="flex"
    alignItems="center"
    justifyContent="center"
    borderRadius="18px"
    w={['200px', '200px', '300px', '350px']}
    h="123px"
    bgColor="#F7F9FE"
  >
    <Image src={icon} boxSize="40px" />
  </Box>
  );
};

export default IconCard;