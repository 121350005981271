import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  HStack,
  useRadioGroup,
  Text,
  Flex,
  Divider,
  useToast,
  Select,
} from "@chakra-ui/react";
import { HomeContext } from "../../../context/HomeContext";
import IconCardXXS from "../../layouts/IconCardXXS";
import Counter from "../../../assets/Counter";
import Picto1 from "./picto4/Picto1.png";
import Picto2 from "./picto5/Picto2.png";
import Picto3 from "./picto5/Picto3.png";
import Picto4 from "./picto5/Picto4.png";
import Picto5 from "./picto5/Picto5.png";
import RadioCard from "../../layouts/RadioCard";

const StepFive = ({ onNext, onBack }) => {
  const [formData, setFormData] = useContext(HomeContext);
  const [isFormValid, setIsFormValid] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const isValid = formData.temperature && formData.factureElectricite;
    setIsFormValid(isValid);
  }, [formData]);

  const handleNextClick = () => {
    if (isFormValid) {
      onNext();
    } else {
      toast({
        title: "Champs manquants",
        description:
          "Veuillez remplir tous les champs obligatoires pour continuer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleTemperatureChange = (value) => {
    console.log(formData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      temperature: value,
    }));
    console.log(formData);
  };

  const handleFactureElectriciteChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      factureElectricite: value,
    }));
  };

  const [selectedPeriodes, setSelectedPeriodes] = useState([]);

  const handlePeriodeChange = (value) => {
    setSelectedPeriodes((prevSelectedPeriodes) => {
      if (prevSelectedPeriodes.includes(value)) {
        // Si la valeur est déjà sélectionnée, retirez-la du tableau
        return prevSelectedPeriodes.filter((periode) => periode !== value);
      } else {
        // Sinon, ajoutez-la au tableau
        return [...prevSelectedPeriodes, value];
      }
    });
  };
  const { getRootProps: periodeGroupProps, getRadioProps: periodeRadioProps } =
    useRadioGroup({
      name: "periodeType",
      defaultValue: formData.periodeType,
      onChange: handlePeriodeChange,
    });
  const optionsPeriode = [
    {
      label: "Matin et soir",
      value: "Matin et soir",
      icon: Picto3,
    },
    {
      label: "Journée",
      value: "Journée",
      icon: Picto4,
    },
    {
      label: "Nuit",
      value: "Nuit",
      icon: Picto5,
    },
  ];

  return (
    <Box width="full" px={20}>
      <Box display={"flex"} flexDirection={"row"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
            Chauffage
          </Heading>
          <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
            Décrivez les détails concernant le chauffage
          </Text>
        </Box>
        <Button
          ml={"60%"}
          w="120px"
          bgColor={"white"}
          color={"#30C9FF"}
          fontWeight={"600"}
          borderRadius={"8px"}
          padding={"8px, 16px, 8px, 21px"}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        borderColor={"#F4F4F4"}
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Box display={"flex"} mr="9%" flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Température de confort
              </Heading>
              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
                Sélectionner la température de confort adaptee
              </Text>
            </Box>{" "}
            <Box display={"flex"} mr={"12%"} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Facture Electrique
              </Heading>

              <Text fontSize="17px" fontWeight="400" color="#8C8C8C" mb={8}>
                Renseigner le montant de la facture électrique
              </Text>
            </Box>
            <Box display={"flex"} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Période d’utilisation
              </Heading>

              <Text fontSize="17px" fontWeight="400" color="#8C8C8C" mb={8}>
                Sélectionner les périodes d’utilisation
              </Text>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"row"}>
            <Box mr={"20px"} flex="1">
              <Box
                display={"flex"}
                w={["325px", "325px", "305px", "385px"]}
                h={"251px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCardXXS icon={Picto1}></IconCardXXS>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  Température Confort
                </Text>
                <Counter initialValue={formData.temperature}
                  onCountChange={(value) =>
                    setFormData({ ...formData, temperature: value })
                  }
                />
              </Box>
            </Box>
            <Divider
              mr={"20px"}
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              color={"#EAEAEA"}
            />
            <Box flex="1">
              <Box
                display={"flex"}
                w={["325px", "325px", "305px", "385px"]}
                mr={"20px"}
                h={"251px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCardXXS icon={Picto2}></IconCardXXS>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  facture électrique
                </Text>
                <Counter initialValue={formData.factureElectricite}
                  onCountChange={(value) =>
                    setFormData({ ...formData, factureElectricite: value })
                  }
                />
              </Box>
            </Box>
            <Divider
              mr={"20px"}
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              color={"#EAEAEA"}
            />
            <Box flex="1">
              <Stack direction="column">
                {optionsPeriode.map((option) => (
                  <RadioCard
                    key={option.value}
                    value={option.value}
                    isChecked={selectedPeriodes.includes(option.value)}
                    onChange={handlePeriodeChange}
                    icon={option.icon}
                  >
                    {option.label}
                  </RadioCard>
                ))}
              </Stack>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" my="3%">
        <Button
          w="120px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={handleNextClick}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default StepFive;
