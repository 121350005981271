import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import PageHeading from "../components/layouts/PageHeading";
import ProgressBar from "../components/ProgressBar";
import ClientEconomies from "../components/forms/ClientEconomies";
import MultiStepForm4 from "../components/forms/MultiStepForm4";

const formSections = [
  "Renseignements du client",
  "Bilan Énergétique",
  "Solutions Énergétique",
  "Économies Énergétique",
];

const PartFour = () => {
  const navigate = useNavigate();
  const currentSection = 3;

  const handleFormSubmit = () => {
    navigate("/");
  };

  return (
    <Box width="full">
      <Box position="sticky" h={"140px"} top={0} bg="white" zIndex={1}>
        {" "}
        <ProgressBar sections={formSections} currentSection={currentSection} />
      </Box>
      {/* <ClientEconomies onSubmit={handleFormSubmit} />
       */}
      <MultiStepForm4 onSubmit={handleFormSubmit} />
    </Box>
  );
};

export default PartFour;
