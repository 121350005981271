import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  FormControl,
  Stack,
  useRadioGroup,
  Text,
  Flex,
  useToast,
  Divider,
  Grid,
} from "@chakra-ui/react";
import { HomeContext } from "../../../context/HomeContext";
import IconCard from "../../layouts/IconCard";
import Counter from "../../../assets/Counter";
import Picto1 from "./picto1/Picto1.png";
import Picto2 from "./picto1/Picto2.png";
import Picto3 from "./picto1/Picto3.png";
import Picto4 from "./picto1/Picto4.png";
import Picto5 from "./picto1/Picto5.png";
import RadioCard from "../../layouts/RadioCard";

const StepOne = ({ onNext, onBack }) => {
  const [formData, setFormData] = useContext(HomeContext);
  const [isFormValid, setIsFormValid] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const isValid = formData.occupationType && formData.occupationType;
    setIsFormValid(isValid);
  }, [formData]);

  const handleNextClick = () => {
    if (isFormValid) {
      onNext();
    } else {
      toast({
        title: "Champs manquants",
        description:
          "Veuillez remplir tous les champs obligatoires pour continuer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleNumberOfOccupantsChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      numberOfOccupants: value,
    }));
  };

  const handleOccupationChange = (nextValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      occupationType: nextValue,
    }));
  };
  const optionsOccupation = [
    {
      label: "Propriétaire occupant",
      value: "Propriétaire occupant",
      icon: Picto2,
    },
    {
      label: "Propriétaire d'une résidence secondaire",
      value: "Propriétaire d'une résidence secondaire",
      icon: Picto3,
    },
    {
      label: "Propriétaire bailleur",
      value: "Propriétaire bailleur",
      icon: Picto4,
    },
    { label: "Locataire", value: "Locataire", icon: Picto5 },
  ];

  const {
    getRootProps: occupationGroupProps,
    getRadioProps: occupationRadioProps,
  } = useRadioGroup({
    name: "occupationType",
    defaultValue: formData.occupationType,
    onChange: handleOccupationChange,
  });

  return (
    <Box width="full" px={20}>
      <Box display={"flex"} flexDirection={"row"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
            Renseignements sur le Client
          </Heading>
          <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
            Saisissez les informations correctes du client
          </Text>
        </Box>
        <Button
          ml={"60%"}
          w="120px"
          bgColor={"white"}
          color={"#30C9FF"}
          fontWeight={"600"}
          borderRadius={"8px"}
          padding={"8px, 16px, 8px, 21px"}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        borderColor={"#F4F4F4"}
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Box
              display={"flex"}
              mr={["14%", "16%", "18%", "26%"]}
              flexDirection={"column"}
            >
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Nombre d’habitant(s)
              </Heading>
              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
                Sélectionnez un nombre d'habitant(s)
              </Text>
            </Box>{" "}
            <Box display={"flex"} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Statut d’occupation
              </Heading>

              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
                Sélectionnez un type d'occupation
              </Text>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"row"}>
            <Box mr={["50px", "50px", "50px", "70px"]} flex="1">
              <Box
                display={"flex"}
                w={["300px", "300px", "320px", "405px"]}
                h={"251px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCard icon={Picto1}></IconCard>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  NOMBRE D’HABITANT(S)
                </Text>
                <Counter
                  initialValue={formData.numberOfOccupants}
                  onCountChange={(value) =>
                    setFormData({ ...formData, numberOfOccupants: value })
                  }
                />
              </Box>
            </Box>
            <Divider
              mr={["50px", "50px", "45px", "80px"]}
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              color={"#EAEAEA"}
            />
            <Box flex="1">
              <FormControl>
                <Stack {...occupationGroupProps()} direction="row">
                  <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    {optionsOccupation.map((option) => (
                      <RadioCard
                        key={option.value}
                        {...occupationRadioProps({ value: option.value })}
                        icon={option.icon}
                      >
                        {option.label}
                      </RadioCard>
                    ))}
                  </Grid>
                </Stack>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" my="3%">
        <Button
          w="120px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={handleNextClick} // Utilisez handleNextClick ici
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default StepOne;
