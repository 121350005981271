import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  HStack,
  useRadioGroup,
  Text,
  Flex,
  useToast,
  Divider,
  Select,
} from "@chakra-ui/react";
import { HomeContext } from "../../../context/HomeContext";
import IconCard from "../../layouts/IconCard";
import Counter from "../../../assets/Counter";
import Picto1 from "./picto3/Picto1.png";
import Picto2 from "./picto3/Picto2.png";
import RadioCard from "../../layouts/RadioCard";

const StepThree = ({ onNext, onBack }) => {
  const [formData, setFormData] = useContext(HomeContext);
  const [isFormValid, setIsFormValid] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const isValid = formData.stockageType && formData.useType;
    setIsFormValid(isValid);
  }, [formData]);

  const handleNextClick = () => {
    if (isFormValid) {
      onNext();
    } else {
      toast({
        title: "Champs manquants",
        description:
          "Veuillez remplir tous les champs obligatoires pour continuer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleCapaciteStockageChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUseChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <Box width="full" px={20}>
      <Box display={"flex"} flexDirection={"row"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
            Eau chaude sanitaire
          </Heading>
          <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
            Décrivez les détails concernant l'eau chaude sanitaire
          </Text>
        </Box>
        <Button
          ml={"50%"}
          w="120px"
          bgColor={"white"}
          color={"#30C9FF"}
          fontWeight={"600"}
          borderRadius={"8px"}
          padding={"8px, 16px, 8px, 21px"}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        borderColor={"#F4F4F4"}
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column" mr="100px">
              {" "}
              {/* Ajoutez un marginRight ici */}
              <Heading fontSize="20px" fontWeight="510" color="gray.700" mb={1}>
                Capacité de stockage de votre ballon
              </Heading>
              <Text fontSize="17px" fontWeight="400" color="#8C8C8C" mb={8}>
                Sélectionnez le type de stockage utilise
              </Text>
            </Box>
            <Box
              display="flex"
              ml={["170px", "170px", "170px", "310px"]}
              flexDirection="column"
            >
              <Heading fontSize="20px" fontWeight="510" color="gray.700" mb={1}>
                Utilisation de l’eau chaude sanitaire
              </Heading>
              <Text fontSize="17px" fontWeight="400" color="#8C8C8C" mb={8}>
                Sélectionnez le type d’eau chaude sanitaire utilise
              </Text>
            </Box>
          </Box>

          <Box display={"flex"} flexDirection={"row"}>
            <Box mr={["10%", "10%", "12%", "15%"]} flex="1">
              <Box
                display={"flex"}
                w={["325px", "325px", "325px", "385px"]}
                h={"251px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCard icon={Picto1}></IconCard>
                <Select
                  mb={"20px"}
                  name="stockageType"
                  width={["313px", "313px", "263px", "363px"]}
                  height={"44px"}
                  fontWeight={"400"}
                  fontSize={"14px"}
                  color={formData.stockageType ? "black" : "#A7A7A7"}
                  gap={"302px"}
                  onChange={(e) =>
                    handleCapaciteStockageChange("stockageType", e.target.value)
                  }
                  value={formData.stockageType}
                >
                  <option value="">Choose</option>
                  <option value="Pas de stockage">Pas de stockage</option>
                  <option value="150 litres">150 litres</option>
                  <option value="200 litres">200 litres</option>
                  <option value="250 litres">250 litres</option>
                  <option value="300 litres et plus">300 litres et plus</option>
                </Select>
              </Box>
            </Box>
            <Divider
              mr={["10%", "10%", "12%", "15%"]}
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              color={"#EAEAEA"}
            />
            <Box flex="1">
              <Box
                display={"flex"}
                w={["325px", "325px", "325px", "385px"]}
                mr={"20px"}
                h={"251px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCard icon={Picto2}></IconCard>
                <Select
                  mb={"20px"}
                  name="useType"
                  width={["313px", "313px", "263px", "363px"]}
                  height={"44px"}
                  fontWeight={"400"}
                  fontSize={"14px"}
                  color={formData.useType ? "black" : "#A7A7A7"}
                  gap={"302px"}
                  onChange={(e) => handleUseChange("useType", e.target.value)}
                  value={formData.useType}
                >
                  <option value="">Choose</option>
                  <option value="Sobre">Sobre</option>
                  <option value="Modérée">Modérée</option>
                  <option value="Normale">Normale</option>
                  <option value="Intensive">Intensive</option>
                  <option value="Balnéo">Balnéo</option>
                </Select>
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" my="3%">
        <Button
          w="120px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={handleNextClick}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default StepThree;
