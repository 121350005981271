import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import StepPre from "./Step1/StepPre"; // Assurez-vous d'importer correctement le nouveau composant StepPre
import StepOne from "./Step1/StepOne";
import StepTwo from "./Step1/StepTwo";
import StepThree from "./Step1/StepThree";
import StepFour from "./Step1/StepFour";

const MultiStepForm = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0); // Commence avec l'étape préliminaire à l'index 0

  const handleNext = () => {
    if (currentStep === 4) { // Mettez à jour l'index pour la dernière étape après l'ajout de StepPre
      navigate("/client-audit"); // Naviguez après la dernière étape
    } else {
      setCurrentStep((prev) => prev + 1); // Avance à l'étape suivante
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1); // Retour à l'étape précédente
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <StepPre onNext={handleNext} />; // Ajoutez le nouveau composant StepPre comme première étape
      case 1:
        return <StepOne onNext={handleNext} onBack={handleBack} />;
      case 2:
        return <StepTwo onNext={handleNext} onBack={handleBack} />;
      case 3:
        return <StepThree onNext={handleNext} onBack={handleBack} />;
      case 4:
        return <StepFour onNext={handleNext} onBack={handleBack} />;
      default:
        return <div>Étape inconnue</div>;
    }
  };

  return (
    <div>
      {renderStep()}
    </div>
  );
};

export default MultiStepForm;