import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  useToast,
  Stack,
  HStack,
  useRadioGroup,
  Text,
  Flex,
  Divider,
  Select,
} from "@chakra-ui/react";
import { HomeContext } from "../../../context/HomeContext";
import IconCard from "../../layouts/IconCard";
import Counter from "../../../assets/Counter";
import Picto1 from "./picto1/Picto1.png";
import Picto2 from "./picto1/Picto2.png";
import Picto3 from "./picto1/Picto3.png";
import Picto4 from "./picto1/Picto4.png";
import Picto5 from "./picto1/Picto5.png";
import Picto6 from "./picto1/Picto6.png";

import RadioCard from "../../layouts/RadioCard";

const StepOne = ({ onNext, onBack }) => {
  const [formData, setFormData] = useContext(HomeContext);
  const [isFormValid, setIsFormValid] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const isValid = formData.protectionType && formData.installationType && formData.ampouleType;
    setIsFormValid(isValid);
  }, [formData]);

  const handleNextClick = () => {
    if (isFormValid) {
      onNext();
    } else {
      toast({
        title: "Champs manquants",
        description:
          "Veuillez remplir tous les champs obligatoires pour continuer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleNumberOfLevelChange = (value) => {
    console.log(formData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      numberOfLevels: value,
    }));
  };

  const handleRadioChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInstallationChange = (nextValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      installationType: nextValue,
    }));
  };

  const {
    getRootProps: installationGroupProps,
    getRadioProps: installationRadioProps,
  } = useRadioGroup({
    name: "installationType",
    defaultValue: formData.installationType,
    onChange: handleInstallationChange,
  });
  const optionsInstallation = [
    {
      label: "Monophasé",
      value: "Monophasé",
      icon: Picto2,
    },
    {
      label: "Triphasé",
      value: "Triphasé",
      icon: Picto3,
    },
  ];

  const handleAmpouleChange = (nextValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ampouleType: nextValue,
    }));
  };
  const { getRootProps: ampouleGroupProps, getRadioProps: ampouleRadioProps } =
    useRadioGroup({
      name: "ampouleType",
      defaultValue: formData.ampouleType,
      onChange: handleAmpouleChange,
    });
  const optionsAmpoule = [
    {
      label: "Ampoule incandescente",
      value: "Ampoule incandescente",
      icon: Picto4,
    },
    {
      label: "Ampoule LED",
      value: "Ampoule LED",
      icon: Picto5,
    },
    {
      label: "Mixte incandescente/LED",
      value: "Mixte incandescente/LED",
      icon: Picto6,
    },
  ];

  const handleSensibiliteChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <Box width="full" px={20}>
      <Box display={"flex"} flexDirection={"row"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
          Renseignements sur le Client
          </Heading>
          <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
          Saisissez les informations correctes du client
          </Text>
        </Box>
        <Button
          ml={"59%"}
          w="120px"
          bgColor={"white"}
          color={"#30C9FF"}
          fontWeight={"600"}
          borderRadius={"8px"}
          padding={"8px, 16px, 8px, 21px"}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        borderColor={"#F4F4F4"}
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Box display={["none", "none", "none", "flex"]} mr={'15%'} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Sensibilité a L'environnement 
              </Heading>
              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
              Décrivez le niveau de sensibilité du client <br/> a la  préservation de notre plante  
              </Text>
            </Box>
            <Box display={["flex", "flex", "flex", "none"]} mr={'15%'} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Sensibilité a L'environnement 
              </Heading>
              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
              Décrivez le niveau de sensibilité <br/>du client  la  préservation de notre planete  
              </Text>
            </Box>
            <Box display={"flex"} mr={'3%'} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
               Type d'installation electrique
              </Heading>

              <Text fontSize="17px" fontWeight="400" color="#8C8C8C" mb={8}>
              Selectionnez un type d'installation
              </Text>
            </Box>
            <Box display={"flex"} ml='6%' flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Type d’ampoule
              </Heading>

              <Text fontSize="17px" fontWeight="400" color="#8C8C8C" mb={8}>
              Sélectionnez un type d’ampoule 
              </Text>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"row"}>
            <Box mr={"40px"} flex="1">
              <Box
                display={"flex"}
                w={["325px", "325px", "325px", "385px"]}

                h={"251px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCard icon={Picto1}></IconCard>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  niveau de sensibilité a l’environnement
                </Text>
                <Select
                  mb={"20px"}
                  name="protectionType"
                  width={["313px", "313px", "263px", "363px"]}

                  height={"44px"}
                  fontWeight={"400"}
                  fontSize={"14px"}
                  color={formData.protectionType ? "black" : "#A7A7A7"}

                  gap={"302px"}
                  onChange={(e) =>
                    handleSensibiliteChange(
                      "protectionType",
                      e.target.value
                    )
                  }
                  value={formData.protectionType}
                >
                  <option value="">Choose</option>
                  <option value="Pas sensible"> Pas sensible</option>
                  <option value="Peu sensible"> Peu sensible</option>
                  <option value="Sensible">Sensible</option>
                  <option value="Très sensible"> Très sensible</option>
                  <option value="Extrêment sensible">Extrêment sensible</option>
                </Select>
              </Box>
            </Box>
            <Divider
              mr={"40px"}
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              color={"#EAEAEA"}
            />
            <Box flex="1"  mr={"40px"}>
            <Stack {...installationGroupProps()} direction="column">
            {optionsInstallation.map((option) => (
              <RadioCard
                key={option.value}
                {...installationRadioProps({ value: option.value })}
                icon={option.icon}
              >
                {option.label}
              </RadioCard>
            ))}
          </Stack>
            </Box>
            <Divider
              mr={"40px"}
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              color={"#EAEAEA"}
            />
            <Box flex="1">
            <Stack {...ampouleGroupProps()} direction="column">
            {optionsAmpoule.map((option) => (
              <RadioCard
                key={option.value}
                {...ampouleRadioProps({ value: option.value })}
                icon={option.icon}
              >
                {option.label}
              </RadioCard>
            ))}
          </Stack>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" my="3%">
        <Button
          w="120px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={handleNextClick}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default StepOne;
