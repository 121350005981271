import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  useToast,
  Stack,
  HStack,
  useRadioGroup,
  Text,
  Flex,
  Divider,
  Select,
  Grid,
  Image,
} from "@chakra-ui/react";
import { HomeContext } from "../../../context/HomeContext";
import IconCard from "../../layouts/IconCard";
import Counter from "../../../assets/Counter";
import Picto1 from "./picto4/Picto1.png";
import Picto2 from "./picto4/Picto2.png";
import Picto3 from "./picto4/Picto3.png";
import Picto4 from "./picto4/Picto4.png";
import Picto5 from "./picto4/Picto5.png";
import Picto6 from "./picto4/Picto6.png";
import Picto7 from "./picto4/Picto7.png";
import Picto8 from "./picto4/Picto8.png";
import Picto9 from "./picto4/Picto9.png";

import RadioCard from "../../layouts/RadioCard";

const StepFour = ({ onNext, onBack }) => {
  const [formData, setFormData] = useContext(HomeContext);
  const [isFormValid, setIsFormValid] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const isValid = formData.sourceType && formData.chauffageType && formData.chauffagesType;
    setIsFormValid(isValid);
  }, [formData]);

  const handleNextClick = () => {
    if (isFormValid) {
      onNext();
    } else {
      toast({
        title: "Champs manquants",
        description:
          "Veuillez remplir tous les champs obligatoires pour continuer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSourceChange = (nextValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sourceType: nextValue,
    }));
  };
  const { getRootProps: sourceGroupProps, getRadioProps: sourceRadioProps } =
    useRadioGroup({
      name: "sourceType",
      defaultValue: formData.sourceType,
      onChange: handleSourceChange,
    });
  const optionsSource = [
    {
      label: "Électricité",
      value: "ELECTRICITE",
      icon: Picto2,
    },
    {
      label: "Gaz naturel",
      value: "GAZ_NATUREL",
      icon: Picto3,
    },
    {
      label: "Condensation",
      value: "CONDENSATION",
      icon: Picto4,
    },
    {
      label: "Fioul",
      value: "FUEL",
      icon: Picto5,
    },
  ];

  const handleChauffagesTypeChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChauffageChange = (nextValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      chauffageType: nextValue,
    }));
  };
  const {
    getRootProps: chauffageGroupProps,
    getRadioProps: chauffageRadioProps,
  } = useRadioGroup({
    name: "chauffageType",
    defaultValue: formData.chauffageType,
    onChange: handleChauffageChange,
  });
  const optionsChauffage = [
    {
      label: "Convecteur électrique",
      value: "Radiateur classique",
      icon: Picto6,
    },
    {
      label: "Plancher chauffant électrique",
      value: "Au sol",
      icon: Picto7,
    },
    {
      label: "Radiateur fonte",
      value: "Radiateur fonte",
      icon: Picto8,
    },
    {
      label: "Mixte",
      value: "Mixte",
      icon: Picto9,
    },
  ];

  return (
    <Box width="full" px={20}>
      <Box display={"flex"} flexDirection={"row"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
            Chauffage
          </Heading>
          <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
            Décrivez les détails concernant le chauffage
          </Text>
        </Box>
        <Button
          ml={"60%"}
          w="120px"
          bgColor={"white"}
          color={"#30C9FF"}
          fontWeight={"600"}
          borderRadius={"8px"}
          padding={"8px, 16px, 8px, 21px"}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        borderColor={"#F4F4F4"}
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Box display={"flex"} mr={["15.5%", "16.5%", "17.5%", "21%"]} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Utilisation du chauffage
              </Heading>
              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
                Sélectionnez le niveau de consommation <br /> correspondant à
                l'utilisation du chauffage
              </Text>
            </Box>{" "}
            <Box display={"flex"} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Source d’énergie
              </Heading>

              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
                Sélectionnez le type de source d’énergie employé pour vous chauffer 
              </Text>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"row"}>
            <Box mr={"70px"} flex="1">
              <Box
                display={"flex"}
                w={["325px", "325px", "325px", "385px"]}
                h={"251px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCard icon={Picto1}></IconCard>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  Utilisation du chauffage
                </Text>
                <Select
                  mb={"20px"}
                  name="chauffagesType"
                  width={["313px", "313px", "263px", "363px"]}
                  height={"44px"}
                  fontWeight={"400"}
                  fontSize={"14px"}
                  color={formData.chauffagesType ? "black" : "#A7A7A7"}
                  gap={"302px"}
                  onChange={(e) =>
                    handleChauffagesTypeChange("chauffagesType", e.target.value)
                  }
                  value={formData.chauffagesType}
                >
                  <option value="">Choose</option>
                  <option value="Sobre"> Sobre</option>
                  <option value="Modérée"> Modérée</option>
                  <option value="Normale">Normale</option>
                  <option value="Élevée"> Élevée</option>
                  <option value="Très élevée">Très élevée</option>
                </Select>
              </Box>
            </Box>
            <Divider
              mr={["80px", "80px", "70px", "80px"]}
              orientation="vertical"
              border={"1px solid"}
              h={"502px"}
              color={"#EAEAEA"}
            />
            <Box flex="1" flexDirection={"column"}>
              <FormControl>
                <Stack {...sourceGroupProps()} direction="column">
                  <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    {optionsSource.map((option) => (
                      <RadioCard
                        key={option.value}
                        {...sourceRadioProps({ value: option.value })}
                        icon={option.icon}
                      >
                        {option.label}
                      </RadioCard>
                    ))}
                  </Grid>
                </Stack>
              </FormControl>
              <Box
                display={"flex"}
                ml={"2%"}
                mt={"10%"}
                flexDirection={"column"}
              >
                <Heading
                  fontSize={"20px"}
                  fontWeight={"510"}
                  color="gray.700"
                  mb={1}
                >
                  Type de chauffage
                </Heading>

                <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
                  Sélectionnez le type de chauffage actuellement employé
                </Text>
                <FormControl>
                  <Stack {...chauffageGroupProps()}>
                  <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                      {optionsChauffage.slice(0, 5).map((option) => (
                        <RadioCard
                          key={option.value}
                          {...chauffageRadioProps({ value: option.value })}
                          icon={option.icon}
                        >
                          {option.label}
                        </RadioCard>
                      ))}
                    </Grid>
                  </Stack>
                </FormControl>
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" my="3%">
        <Button
          w="120px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={handleNextClick}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default StepFour;
