import React, { useState } from "react";
import { Box, Input, Button, VStack, Image, Text } from "@chakra-ui/react";
import axios from "axios";
import logo from "../assets/logo.png"; 
import { useNavigate } from "react-router-dom";
import backHome from "../assets/backHome.png";
import bglogout from "../assets/bglogout.png";

const PasswordPrompt = ({ onPasswordSubmit }) => {
  const [formData, setFormData] = useState({
    name: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  
  const handleSubmit = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/login`, formData)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("admin", response.data.admin);
        localStorage.setItem("name", response.data.name);
        if (typeof onPasswordSubmit === "function") {
          onPasswordSubmit(response.data.token);
        }
        console.log("connected");
        navigate("/");
        window.location.reload(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Box display="flex" height="100vh">
      {/* Left side with VStack for login */}
      <VStack
        spacing={4}
        p={8}
        bg="white"
        width="50%" // Adjust width as per your design
        alignItems="center"
        justifyContent="center"
        boxShadow="none"
      >
        {/* Logo at the top left */}
        <Box position="absolute" top="0" left="0" p={4}>
          <Image src={logo} w={"170px"} alt="Logo" />
        </Box>

        {/* Title */}
        <Text fontSize="3xl" size={"36px"} color="gray.700" fontWeight={"590"}>
          Bienvenue
        </Text>
        <Text fontSize="md" color="gray.500" mb={6}>
          Connectez-vous à votre compte
        </Text>

        {/* Login Form Starts Here */}
        <Box w={["300px", "300px", "300px", "400px"]} h={"70px"}>
          <Input
            placeholder="Nom d'utilisateur"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            size="lg"
            border="1px"
            borderColor="gray.300"
            borderRadius="10px"
            bgColor={"#FFFFFF"}
            color={"#D9D9D9"}
          />
        </Box>

        <Box w={["300px", "300px", "300px", "400px"]} h={"70px"}>
          <Input
            placeholder="Mot de passe"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            size="lg"
            border="1px"
            borderColor="gray.300"
            borderRadius="10px"
            bgColor={"#FFFFFF"}
            color={"#D9D9D9"}
          />
        </Box>

        <Button
          bgColor={"#30C9FF"}
          color={"#FAFBFF"}
          w={["300px", "300px", "300px", "400px"]}
          h={"55px"}
          fontSize={"18px"}
          onClick={handleSubmit}
        >
          Se connecter
        </Button>
        {/* Login Form Ends Here */}
      </VStack>

      {/* Right side with background image */}
      <Box
        width="50%" // Ajustez la largeur selon votre design
        height="100vh"
        backgroundImage={`url(${bglogout})`}
        bgPosition="center right" // Cela centrera l'image horizontalement et verticalement
        bgRepeat="no-repeat"
        bgSize="cover"
        borderTopRightRadius="100px" // Cela crée un coin arrondi
        transform="scaleX(-1)" // Cela inverse l'image horizontalement
      >
        {/* Contenu ici */}
      </Box>
    </Box>
  );
};

export default PasswordPrompt;
