import { Button, Flex, Input } from '@chakra-ui/react';
import React, { useState, useEffect } from "react";

const CounterXS = ({ initialValue, onCountChange }) => {
  const [count, setCount] = useState(Number(initialValue) || 1);
  const [inputValue, setInputValue] = useState(String(initialValue) || "1");

  useEffect(() => {
    setCount(Number(initialValue));
    setInputValue(String(initialValue));
  }, [initialValue]);

  const updateCount = (newCount) => {
    const validatedCount = Math.max(0.1, newCount); // Autoriser les valeurs décimales jusqu'à 0.1
    setCount(validatedCount);
    setInputValue(String(validatedCount));
    onCountChange(validatedCount);
  };

  const handleClickMinus = () => {
    const newValue = parseFloat(inputValue) - 1;
    updateCount(newValue);
  };

  const handleClickPlus = () => {
    const newValue = parseFloat(inputValue) + 1;
    updateCount(newValue);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value.replace(/,/g, '.')); // Remplacer les virgules par des points pour la conversion
  };

  const handleBlur = () => {
    const newCount = parseFloat(inputValue);
    if (!isNaN(newCount) && newCount >= 0.1) {
      updateCount(newCount);
    } else {
      setInputValue(String(count));
    }
  };

  // Ajuster dynamiquement la taille du texte en fonction de la longueur de l'inputValue
  const textSize = inputValue.length > 3 ? "20px" : "26.71px";
  const inputWidth = inputValue.length > 3 ? "100px" : "80px";

  return (
    <Flex align="center" justify="center" gap="5px" mb="10px">
      <Button w={["51px", "51px", "51px", "71px"]} onClick={handleClickMinus} {...buttonProps}>-</Button>
      <Input
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        fontSize={textSize}
        width={inputWidth}
        {...inputProps}
      />
      <Button w={["51px", "51px", "51px", "71px"]} onClick={handleClickPlus} {...buttonProps}>+</Button>

    </Flex>
  );
};

const buttonProps = {
  backgroundColor: "#F7F9FE",
  color: "#30C9FF",
  border: "none",
  height: "48px",
  fontWeight: "semibold",
  bg: "#f5f6f7",
  fontSize: "30px",
  borderColor: "#ccd0d5",
  _hover: { bg: '#30C9FF', color: '#F7F9FE' },
  _active: {
    bg: '#dddfe2',
    transform: 'scale(0.98)',
    borderColor: '#bec3c9',
  },
};

const inputProps = {
  textAlign: "center",
  fontWeight: "400",
  color: "#555770",
  borderRadius: "5px",
  border: "none",
  _focus: { boxShadow: "none" },
  bg: "transparent",
};

export default CounterXS;
