import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import StepOne from "./Step2/StepOne";
import StepTwo from "./Step2/StepTwo";
import StepThree from "./Step2/StepThree";
import StepFour from "./Step2/StepFour";
import StepFive from "./Step2/StepFive";
import StepSix from "./Step2/StepSix";
import StepSeven from "./Step2/StepSeven";

const MultiStepForm2 = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0); // Commence avec l'étape préliminaire à l'index 0

  const handleNext = () => {
    if (currentStep === 6) {
      navigate("/client-energetique"); // Naviguez après la dernière étape
    } else {
      setCurrentStep((prev) => prev + 1); // Avance à l'étape suivante
    }
  };

  const handleBack = () => {
    if (currentStep === 0) {
      navigate("/client-info"); // Rediriger vers "/client-info" lorsque vous êtes à la première étape
    } else if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1); // Retour à l'étape précédente si pas la première
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <StepOne onNext={handleNext} onBack={handleBack} />;
      case 1:
        return <StepTwo onNext={handleNext} onBack={handleBack} />;
      case 2:
        return <StepThree onNext={handleNext} onBack={handleBack} />;
      case 3:
        return <StepFour onNext={handleNext} onBack={handleBack} />;
      case 4:
        return <StepFive onNext={handleNext} onBack={handleBack} />;
      case 5:
        return <StepSix onNext={handleNext} onBack={handleBack} />;
      case 6:
        return <StepSeven onNext={handleNext} onBack={handleBack} />;
      default:
        return <div>Étape inconnue</div>;
    }
  };

  return <div>{renderStep()}</div>;
};

export default MultiStepForm2;
