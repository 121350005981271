import React, { useContext } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Flex,
  Image,
  useToast,
} from "@chakra-ui/react";
import { HomeContext } from "../context/HomeContext";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import book from "../assets/book.pdf";
import IconCardXXL from "../components/layouts/IconCardXXL";
import Picto1 from "./picto/Picto1.png";
import Picto2 from "./picto/Picto2.png";
import Picto3 from "./picto/Picto3.png";

const Home = ({ onNext, onBack }) => {
  const [formData, setFormData, defaultFormData] = useContext(HomeContext);

  const navigate = useNavigate();
  const toast = useToast();

  const handleStart = () => {
    navigate("client-info");
  };
  const handleManuals = () => {
    navigate("manuals");
  };

  const handleReset = () => {
    localStorage.removeItem("formData");

    // Mettre à jour l'état local ou le contexte ici pour réinitialiser les champs du formulaire
    setFormData({ ...defaultFormData }); // Réinitialise l'état du formulaire avec des valeurs par défaut

    // Affiche le toast de confirmation
    toast({
      title: "Formulaire réinitialisé",
      description: "Le formulaire a été réinitialisé avec succès.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    // Rafraîchir la page
    window.location.reload();
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = book;
    link.setAttribute("download", "Nom_du_fichier_téléchargé"); // Ou le nom du fichier que vous voulez
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleLogout = () => {
    localStorage.clear(); // Clear all local storage items
    toast({
      title: "Déconnexion réussie.",
      description: "Vous avez été déconnecté avec succès.",
      status: "info",
      duration: 5000,
      isClosable: true,
    });
    navigate("/"); // Redirect to the login page or home page
    window.location.reload();
  };

  return (
    <Box width="full" px={20}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb="30px"
        mt="20px"
      >
        <Image src={logo} w="150px" alt="Home Solution Logo" />
        <Button
          w="150px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={handleLogout}
        >
          Déconnexion
        </Button>
      </Flex>
      <Box display={"flex"} flexDirection={"row"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
            Sélectionner votre objectif
          </Heading>
          <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
            Sélectionner une option pour continuer
          </Text>
        </Box>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        borderColor={"#F4F4F4"}
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        justifyContent={"center"}
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"row"}>
          <Box mr={"20px"} flex="1">
            <Box
              display={"flex"}
              w={"289px"}
              mb={"20px"}
              h={"418px"}
              flexDirection={"column"}
              alignItems={"center"}
              borderRadius={"20px"}
              padding={"10px, 10px, 20px, 10px"}
              borderWidth={"1px"}
            >
              <IconCardXXL icon={Picto1}></IconCardXXL>
              <Text
                mt={"20px"}
                fontSize={"25px"}
                fontWeight="510"
                textTransform="uppercase" // Makes text uppercase
              >
                CONFIGURER
              </Text>
              <Text
                fontWeight="510"
                mt={"10px"}
                fontSize={"20px"}
                mb={"35px"}
                color={"#8C8C8C"}
                textAlign="left" // Aligns text to the left
              >
                Commencer votre étude
              </Text>
              <Button
                w="238px"
                bgColor="#30C9FF"
                color="#FCFDFF"
                borderRadius="8px"
                padding="8px 16px"
                onClick={handleStart}
              >
                COMMENCER
              </Button>
            </Box>
          </Box>
          <Box flex="1" mr={"20px"}>
            <Box
              display={"flex"}
              w={"289px"}
              mb={"20px"}
              h={"418px"}
              flexDirection={"column"}
              alignItems={"center"}
              borderRadius={"20px"}
              padding={"10px, 10px, 20px, 10px"}
              borderWidth={"1px"}
            >
              <IconCardXXL icon={Picto2}></IconCardXXL>
              <Text
                mt={"20px"}
                fontSize={"25px"}
                fontWeight="510"
                textTransform="uppercase"
              >
                REINITALISER
              </Text>
              <Text
                fontWeight="510"
                mt={"10px"}
                fontSize={"20px"}
                mb={"35px"}
                color={"#8C8C8C"}
                textAlign="left"
              >
                Reinitialiser votre étude
              </Text>
              <Button
                w="238px"
                bgColor="#30C9FF"
                color="#FCFDFF"
                borderRadius="8px"
                padding="8px 16px"
                onClick={handleReset}
              >
                RESET
              </Button>
            </Box>
          </Box>
          <Box flex="1">
            <Box
              display={"flex"}
              w={"289px"}
              mb={"20px"}
              h={"418px"}
              flexDirection={"column"}
              alignItems={"center"}
              borderRadius={"20px"}
              padding={"10px, 10px, 20px, 10px"}
              borderWidth={"1px"}
            >
              <IconCardXXL icon={Picto3}></IconCardXXL>
              <Text
                mt={"20px"}
                fontSize={"25px"}
                fontWeight="510"
                textTransform="uppercase" // Makes text uppercase
              >
                TELECHARGER
              </Text>
              <Text
                fontWeight="510"
                mt={"10px"}
                fontSize={"20px"}
                mb={"35px"}
                color={"#8C8C8C"}
                textAlign="left" // Aligns text to the left
              >
                Déscriptifs produits
              </Text>
              <Button
                w="238px"
                bgColor="#30C9FF"
                color="#FCFDFF"
                borderRadius="8px"
                padding="8px 16px"
                onClick={handleManuals}
              >
                DOWNLOAD
              </Button>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Home;
