import { Button, Flex, Input } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

const Counter = ({ initialValue = 0, onCountChange }) => {
  const [count, setCount] = useState(initialValue);
  const [inputValue, setInputValue] = useState(String(initialValue));

  useEffect(() => {
    setCount(initialValue);
    setInputValue(String(initialValue));
  }, [initialValue]);

  const updateCount = (newCount) => {
    setCount(newCount);
    setInputValue(String(newCount));
    onCountChange(newCount);
  };

  const handleClickMinus = () => {
    // Permet de descendre à 0
    const newCount = count - 1 >= 0 ? count - 1 : 0;
    updateCount(newCount);
  };

  const handleClickPlus = () => {
    updateCount(count + 1);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value.replace(/,/g, '.'));
  };

  const handleBlur = () => {
    let newCount = parseFloat(inputValue.replace(/,/g, '.'));
    if (!isNaN(newCount) && newCount >= 0) { // Permet d'accepter 0
      updateCount(newCount);
    } else {
      setInputValue(String(count)); // Réinitialiser avec la valeur du compteur actuelle si invalide
    }
  };

  return (
    <Flex align="center" justify="center" gap="20px" mb="10px">
      <Button w={["40px", "50px", "70px", "91px"]} onClick={handleClickMinus} {...buttonProps}>-</Button>
      <Input
        fontSize={inputValue.length > 3 ? "16px" : "26.71px"}
        w={["60px", "60px", "70px", "100px"]} // Ajustez selon la taille souhaitée
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        {...inputProps}
      />
      <Button w={["40px", "50px", "70px", "91px"]} onClick={handleClickPlus} {...buttonProps}>+</Button>
    </Flex>
  );
};
// Les props des boutons et inputs restent inchangés

// Propriétés réutilisables pour les boutons
const buttonProps = {
  backgroundColor: "#F7F9FE",
  color: "#30C9FF",
  border: "none",
  height: "48px",
  fontWeight: "semibold",
  bg: "#f5f6f7",
  fontSize: "30px",
  borderColor: "#ccd0d5",
  _hover: { bg: "#30C9FF", color: "#F7F9FE" },
  _active: {
    bg: "#dddfe2",
    transform: "scale(0.98)",
    borderColor: "#bec3c9",
  },
};

// Propriétés réutilisables pour l'input
const inputProps = {
  textAlign: "center",
  fontWeight: "400",
  color: "#555770",
  borderRadius: "5px",
  border: "none", // Supprime la bordure
  _focus: { boxShadow: "none" }, // Supprime l'ombre lors de la mise au point
  bg: "transparent", // Rend l'arrière-plan transparent
};

export default Counter;
