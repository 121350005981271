import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Heading,
  CircularProgress,
  CircularProgressLabel,
  Text,
  Flex,
} from "@chakra-ui/react";
import { HomeContext } from "../../../context/HomeContext";

const StepTwo = ({ onNext, onBack }) => {
  const [formData, setFormData, defaultFormData] = useContext(HomeContext);
  const [animatedSavings, setAnimatedSavings] = useState(0); // État local pour l'animation
  const intervalRef = useRef(null); // Référence pour l'intervalle d'animation

  const calculateSavingsPercentage = () => {
    const totalFutureExpenses =
      formData.depenseFutureBallon +
      formData.depenseFuturePanneaux +
      formData.depenseFuturePompe;
    const annualConsumption = formData.consoAnnuelle;

    if (annualConsumption > 0) {
      const calculatedPercentage =
        (totalFutureExpenses / annualConsumption) * 100;
      const savingsPercentage = 100 - calculatedPercentage; // Soustraire le pourcentage calculé de 100 pour obtenir le pourcentage d'économies
      return savingsPercentage.toFixed(0); // Formate le résultat pour ne pas avoir de décimales
    }
    return "0";
  };

  useEffect(() => {
    const savingsPercentage = calculateSavingsPercentage();
    const target = parseInt(savingsPercentage, 10);
    setAnimatedSavings(0); // Réinitialise l'animation
    clearInterval(intervalRef.current); // Efface l'ancien intervalle si existant

    intervalRef.current = setInterval(() => {
      setAnimatedSavings((prev) => {
        if (prev >= target) {
          clearInterval(intervalRef.current);
          return target;
        }
        return prev + 1;
      });
    }, 20);
    return () => clearInterval(intervalRef.current);
  }, [formData]);

  return (
    <Box width="full" px={20}>
      <Flex justifyContent="space-between" alignItems="center" mb="8">
        <Box flex="1">
          <Heading fontSize={"28px"} fontWeight={"510"}>
            Vos économies après avoir utilisé nos solutions
          </Heading>
          <Text color="#8C8C8C" fontWeight={"400"} fontSize="20px">
            Vos économies affichées en pourcentage
          </Text>
        </Box>
        <Button
          w="120px"
          bgColor={"white"}
          color={"#30C9FF"}
          fontWeight={"600"}
          borderRadius={"8px"}
          padding={"8px, 16px, 8px, 21px"}
          onClick={onBack}
        >
          Back
        </Button>
      </Flex>

      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        borderColor={"#F4F4F4"}
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        justifyContent={"center"}
        bgColor={"white"}
      >
        <Box position="relative" mb={"40px"} display="inline-block">
          <CircularProgress
            value={animatedSavings}
            color="#3CBDFE"
            size="200px"
            thickness="8px"
            capIsRound
          >
            <CircularProgressLabel
              fontWeight="bold"
              fontSize="4xl"
              color="#3CBDFE"
            >
              {`${animatedSavings}%`}
            </CircularProgressLabel>
          </CircularProgress>
          <Text
            position="absolute"
            mt={"10px"}
            width="100%"
            textAlign="center"
            color={"#8C8C8C"}
            fontSize="20px"
          >
            Vos économies
          </Text>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" mt="4">
        <Button
          w="120px"
          bgColor="#30C9FF"
          color="white"
          borderRadius="8px"
          padding="8px 16px"
          onClick={onNext}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default StepTwo;
