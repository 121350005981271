import React from "react";
import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png"; 
import PageHeading from "../components/layouts/PageHeading";
import ProgressBar from "../components/ProgressBar";
import ClientInfo from "../components/forms/ClientInfo";
import MultiStepForm from "../components/forms/MultiStepForm";


const formSections = [
  "Renseignements du client",
  "Bilan Énergétique",
  "Solutions Énergétique",
  "Économies Énergétique",
];

const PartOne = () => {
  const navigate = useNavigate();
  const currentSection = 0;

  const handleFormSubmit = () => {
    navigate("/client-audit");
    window.scrollTo(0, 0);
  };

  return (
    <Box width="full">
      <Box position="sticky" h={"140px"} top={0} bg="white" zIndex={1}>
        {" "}
        <ProgressBar sections={formSections} currentSection={currentSection} />
      </Box>
      <MultiStepForm onSubmit={handleFormSubmit} />
    </Box> 
  );
};

export default PartOne;
