import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  HStack,
  useToast,
  useRadioGroup,
  Text,
  Flex,
  Divider,
  Grid,
  Image,
} from "@chakra-ui/react";
import { HomeContext } from "../../../context/HomeContext";
import IconCard from "../../layouts/IconCard";
import Counter from "../../../assets/Counter";
import Picto1 from "./picto2/Picto1.png";
import Picto2 from "./picto2/Picto2.png";
import Picto3 from "./picto2/Picto3.png";
import Picto4 from "./picto2/Picto4.png";
import Picto5 from "./picto2/Picto5.png";
import Picto6 from "./picto2/Picto6.png";
import Picto7 from "./picto2/Picto7.png";
import RadioCard from "../../layouts/RadioCard";

const StepTwo = ({ onNext, onBack }) => {
  const [formData, setFormData] = useContext(HomeContext);
  const [isFormValid, setIsFormValid] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const isValid = formData.energieType && formData.sanitaireType;
    setIsFormValid(isValid);
  }, [formData]);

  const handleNextClick = () => {
    if (isFormValid) {
      onNext();
    } else {
      toast({
        title: "Champs manquants",
        description:
          "Veuillez remplir tous les champs obligatoires pour continuer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEnergieChange = (nextValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      energieType: nextValue,
    }));
  };
  const { getRootProps: energieGroupProps, getRadioProps: energieRadioProps } =
    useRadioGroup({
      name: "energieType",
      defaultValue: formData.energieType,
      onChange: handleEnergieChange,
    });
  const optionsEnergie = [
    {
      label: "Électricité",
      value: "Ballon",
      icon: Picto1,
    },
    {
      label: "Gaz naturel",
      value: "Chaudiere",
      icon: Picto2,
    },
    {
      label: "Fioul",
      value: "Chaudiere",
      icon: Picto3,
    },
  ];

  const handleSanitaireChange = (nextValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sanitaireType: nextValue,
    }));
  };
  const {
    getRootProps: sanitaireGroupProps,
    getRadioProps: sanitaireRadioProps,
  } = useRadioGroup({
    name: "sanitaireType",
    defaultValue: formData.sanitaireType,
    onChange: handleSanitaireChange,
  });
  const optionsSanitaire = [
    {
      label: "Chauffe-eau électrique",
      value: "Chauffe-eau électrique",
      icon: Picto4,
    },
    {
      label: "Chauffe-eau thermodynamique",
      value: "Chauffe-eau thermodynamique",
      icon: Picto5,
    },
    {
      label: "Module ECS intégré à PAC OU CHAUDIERE",
      value: "Module ECS intégré à PAC OU CHAUDIERE",
      icon: Picto6,
    },
    {
      label: "Chauffe-eau solaire",
      value: "Chauffe-eau solaire",
      icon: Picto7,
    },
  ];

  return (
    <Box width="full" px={20}>
      <Box display={"flex"} flexDirection={"row"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
            Eau chaude sanitaire
          </Heading>
          <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
            Décrivez les détails concernant l'eau chaude sanitaire
          </Text>
        </Box>
        <Button
          ml={"54%"}
          w="120px"
          bgColor={"white"}
          color={"#30C9FF"}
          fontWeight={"600"}
          borderRadius={"8px"}
          padding={"8px, 16px, 8px, 21px"}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        borderColor={"#F4F4F4"}
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Box display={"flex"} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Source Énergétique
              </Heading>
              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
                Sélectionnez un type de source d’énergie a utiliser
              </Text>
            </Box>{" "}
            <Box
              display={"flex"}
              ml={["12%", "12%", "12%", "29%"]}
              flexDirection={"column"}
            >
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Type d’eau chaude sanitaire
              </Heading>

              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
                Sélectionnez le type de d’eau chaude sanitaire a utiliser
              </Text>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"row"}>
            <Box mr={"18%"} flex="1">
              <Stack {...energieGroupProps()} direction="column">
                {optionsEnergie.map((option) => (
                  <RadioCard
                    key={option.value}
                    {...energieRadioProps({ value: option.value })}
                    icon={option.icon}
                  >
                    {option.label}
                  </RadioCard>
                ))}
              </Stack>
            </Box>
            <Divider
              mr={"12%"}
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              color={"#EAEAEA"}
            />
            <Box flex="1">
              <FormControl>
                <Stack {...sanitaireGroupProps()} direction="column">
                  <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    {optionsSanitaire.map((option) => (
                      <RadioCard
                        key={option.value}
                        {...sanitaireRadioProps({ value: option.value })}
                        icon={option.icon}
                        iconName={option.label} // Supposons que 'Chauffe-eau solaire' correspond à `Picto7`
                      >
                        {option.label}
                      </RadioCard>
                    ))}
                  </Grid>
                </Stack>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" my="3%">
        <Button
          w="120px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={handleNextClick}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default StepTwo;
