import React from "react";
import {
  Box,
  Text,
  Button,
  SimpleGrid,
  Image,
  Heading,
} from "@chakra-ui/react";
import logo from "../assets/logo.png";
import book from "../assets/book.pdf";
import { useNavigate } from "react-router-dom";
import panorecomb from "../assets/panorecomfb425_fiche_tech.pdf";
import aristonNimbus90 from "../assets/Ariston NIMBUS NET. 90 et 110s.pdf";
import aristonNimbus120 from "../assets/ariston nimbus 120S ET 150S.pdf";
import atlanticAirEau from "../assets/atlantic air eau .pdf";
import atlanticDuo from "../assets/atlantic duo .pdf";
import ariston80 from "../assets/PAC air-eau Nimbus Plus S Net Ariston.pdf";
import dainkinAirEau from "../assets/daikin air eau .pdf";
import thompson from '../assets/THOMPSON.pdf'
import fhe from '../assets/FHE.pdf'
import Picto3 from "./picto/Picto3.png";

const manuals = [
  {
    id: 3,
    name: "Ariston Nimbus 90 et 110",
    file: aristonNimbus90,
    icon: Picto3,
  },
  { id: 6, name: "Pac Air Eau Atlantic Duo", file: atlanticDuo, icon: Picto3 },
  { id: 7, name: "Pac Air Eau Daikin", file: dainkinAirEau, icon: Picto3 },
  {
    id: 4,
    name: "Ariston Nimbus 120 et 150",
    file: aristonNimbus120,
    icon: Picto3,
  },
  { id: 8, name: "Ariston Nimbus 80", file: ariston80, icon: Picto3 },
  { id: 9, name: "Thompson", file: thompson, icon: Picto3 },
  { id: 10, name: "FHE", file: fhe, icon: Picto3 },

];

const Pac = () => {
  const handleDownload = (file, name) => {
    const link = document.createElement("a");
    link.href = file;
    link.setAttribute("download", name); // Utilisez le nom du manuel pour le téléchargement
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const navigate = useNavigate();

  return (
    <Box width="full" px={20}>
      <Box mt={"20px"} display={"flex"} flexDirection={"row"}>
        <Box cursor="pointer" onClick={() => navigate("/")}>
          <Image src={logo} w="150px" alt="Home Solution Logo" />
        </Box>
        <Heading mt={"50px"} ml={"300px"}>
          Plaquettes Produits PAC
        </Heading>
      </Box>

      <Box
        p={5}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Text mb={7}>
          Téléchargez les manuels des produits pour plus d'informations
          techniques et d'utilisation.
        </Text>
        <SimpleGrid columns={4} spacing={10}>
          {manuals.map((manual) => (
            <Box
              key={manual.id}
              borderWidth="1px"
              borderRadius="20px"
              p="10px"
              textAlign="center"
            >
              <Image
                src={manual.icon}
                alt={`Icon for ${manual.name}`}
                boxSize="120px"
                m="auto"
              />
              <Text
                mt="20px"
                fontSize="18px"
                fontWeight="510"
                textTransform="uppercase"
              >
                {manual.name}
              </Text>
              <Button
                mt="10px"
                w="200px"
                bgColor="#30C9FF"
                color="#FCFDFF"
                borderRadius="8px"
                padding="8px 16px"
                onClick={() => handleDownload(manual.file, manual.name)}
              >
                Télécharger
              </Button>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Pac;
