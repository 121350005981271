import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { HomeContext } from "./context/HomeContext";
import Home from "./pages/Home";
import Clients from "./pages/Clients";
import PasswordPrompt from "./components/Login";
import Admin from "./pages/Admin";
import PartOne from "./pages/PartOne";
import PartTwo from "./pages/PartTwo";
import PartThree from "./pages/PartThree";
import PartFour from "./pages/PartFour";
import Manuals from './pages/Manuals';
import Panneaux from './pages/Panneaux';
import Pac from './pages/Pac';
import Ballon from './pages/Ballon';

const AppRouter = () => {
  const [adminAccess, setAdminAccess] = useState(() => {
    const admin = localStorage.getItem("admin") === "true";
    return admin;
  });
  const [hasAccess, setHasAccess] = useState(() => {
    const token = localStorage.getItem("token");
    return !!token;  // Convertit la présence du token en booléen
  });

  //Context]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]

  const defaultFormData = {
    sexe: "",
    nom: "",
    prenom: "",
    adresse: "",
    provinceOuIdf:"",
    ville: "",
    departement: "",
    region: "",
    email: "",
    cp: "",
    tel1: "",
    tel2: "",
    occupationType: "",
    numberOfOccupants: 1,
    constructionYear: 0,
    surfaceAuSol: 0,
    surfaceHabitable: 0,
    hauteurSousPlafond: 0,
    superficieToiture: 0,
    orientationToiture: "",
    inclinaisonToiture: "",
    altitude: "",
    numberOfRooms: 1,
    housePosition: "",
    houseShape: "",
    numberOfLevels: "",
    installationType: "",
    protectionType: "",
    ampouleType: "",
    energieType: "",
    sanitaireType: "",
    stockageType: "",
    useType: "",
    sourceType: "",
    chauffageType: "",
    chauffagesType: "",
    temperature: 0,
    factureElectricite: 0,
    factureSecondaire: 0,
    revenuFiscal: 0,
    periodeType: "",
    aideColor: "",
    consommationElectrique: 0,
    depenseChauffage: 0,
    depenseECS: 0,
    depenseMenager: 0,
    depenseEclairage: 0,
    baseTemperature: 0,
    solarCoefficient: 0,
    besoinPuissanceChauffage: 0,
    typeDePompeAChaleur: "",
    
    installationMaximum: 0,
    ballonThermoSelected: "",
    pompeSelected: "",
    pompeModeleSelected: "",
    panneauxSelected: "",
    installationRecommandee: "",

    consoAnnuellePompe: 0,
    depenseFuturePompe: 0,
    coutProjetPompe: 0,
    maPrimePompe:0,
    aidesCeePompe:0,

    consoAnnuelleBallon: 0,
    depenseFutureBallon: 0,
    coutProjetBallon: 0,
    maPrimeBallon:0,
    aidesCeeBallon:0,

    consoAnnuellePanneaux: 0,
    productionAnnuellePanneaux: 0,
    depenseFuturePanneaux: 0,
    coutProjetPanneaux: 0,
    primeAutoConsoPanneaux:0,
    recuperationTvaPanneaux:0,

    consoAnnuelle:0,
    coutProjet: 0,
    totalAide: 0,
    coutProjetAideDeduite: 0,
    coutFacture20ans:0,
    coutFacture20ansAvecProjet:0,
    gainSur20ans:0,
    economies:0,
  };

  const [formData, setFormData] = useState(() => {
    const savedFormData = localStorage.getItem('formData');
    return savedFormData ? JSON.parse(savedFormData) : defaultFormData;
  });

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  return (
    <HomeContext.Provider value={[formData, setFormData, defaultFormData]}>
        <Router>
          {!hasAccess ? (
            <PasswordPrompt />
          ) : (
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/client-info" element={<PartOne />} />
              <Route path="/client-audit" element={<PartTwo />} />
              <Route path="/client-energetique" element={<PartThree />} />
              <Route path="/client-economies" element={<PartFour />} />
              <Route path="/manuals" element={<Manuals />} />
              <Route path="/panneaux" element={<Panneaux />} />
              <Route path="/pac" element={<Pac />} />
              <Route path="/ballon" element={<Ballon />} />

              {adminAccess && <Route path="/admin" element={<Admin />} />}
              {adminAccess && <Route path="/clients" element={<Clients />} />}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          )}
        </Router>
    </HomeContext.Provider>
  );
};

export default AppRouter;
