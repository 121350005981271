import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  HStack,
  useRadioGroup,
  Text,
  useToast,
  Flex,
  Divider,
  Select,
} from "@chakra-ui/react";
import { HomeContext } from "../../../context/HomeContext";
import IconCard from "../../layouts/IconCard";
import Counter from "../../../assets/Counter";
import Picto1 from "./picto6/Picto1.png";
import Picto2 from "./picto6/Picto2.png";
import RadioCard from "../../layouts/RadioCard";

const StepSix = ({ onNext, onBack }) => {
  const [formData, setFormData] = useContext(HomeContext);
  const [isFormValid, setIsFormValid] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const isValid = formData.revenuFiscal;
    setIsFormValid(isValid);
  }, [formData]);

  const handleNextClick = () => {
    if (isFormValid) {
      onNext();
    } else {
      toast({
        title: "Champs manquants",
        description:
          "Veuillez remplir tous les champs obligatoires pour continuer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRevenuFiscalChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      revenuFiscal: value,
    }));
  };

  const handleFactureSecondaireChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      factureSecondaire: value,
    }));
    console.log(formData);
  };
  return (
    <Box width="full" px={20}>
      <Box display={"flex"} flexDirection={"row"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
            Vos Factures
          </Heading>
          <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
            Décrivez les détails concernant vos factures
          </Text>
        </Box>
        <Button
          ml={"54%"}
          w="120px"
          bgColor={"white"}
          color={"#30C9FF"}
          fontWeight={"600"}
          borderRadius={"8px"}
          padding={"8px, 16px, 8px, 21px"}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        borderColor={"#F4F4F4"}
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column">
              <Heading fontSize="20px" fontWeight="510" color="gray.700" mb={1}>
              Facture secondaire ( Gaz ou Fioul si chaudière )  
              </Heading>
              <Text fontSize="17px" fontWeight="400" color="#8C8C8C" mb={8}>
              Sélectionnez le montant de la facture secondaire
              </Text>
            </Box>
            <Box display="flex" ml={["160px", "180px", "180px", "240px"]} flexDirection="column">
              <Heading fontSize="20px" fontWeight="510" color="gray.700" mb={1}>
              Revenu Fiscal de reference
              </Heading>
              <Text fontSize="17px" fontWeight="400" color="#8C8C8C" mb={8}>
              Sélectionnez le montant du revenu fiscal
              </Text>
            </Box>
          </Box>

          <Box display={"flex"}  flexDirection={"row"}>
            <Box flex="1" mr={['14%', '14%', '9%', '14%']}>
              <Box
                display={"flex"}
                w={["325px", "325px", "325px", "385px"]}
                h={"251px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCard icon={Picto1}></IconCard>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  facture Secondaire (gaz ou fioul)
                </Text>
                <Counter initialValue={formData.factureSecondaire}
                  onCountChange={(value) =>
                    setFormData({ ...formData, factureSecondaire: value })
                  }
                />
              </Box>
            </Box>
            <Divider mr={['14%', '14%', '11%', '14%']}
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              color={"#EAEAEA"}
            />
            <Box flex="1">
              <Box
                display={"flex"}
                w={["325px", "325px", "325px", "385px"]}
                h={"251px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCard icon={Picto2}></IconCard>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  Revenu fiscal
                </Text>
                <Counter initialValue={formData.revenuFiscal}
                  onCountChange={(value) =>
                    setFormData({ ...formData, revenuFiscal: value })
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" my="3%">
        <Button
          w="346px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={handleNextClick}
        >
          Calculer mes consomation energetique
        </Button>
      </Flex>
    </Box>
  );
};

export default StepSix;
