import React, { useContext, useState } from "react";
import { Box, Badge, Text, Heading } from "@chakra-ui/react";
import { HomeContext } from "../../context/HomeContext";

const DetailFoyer = () => {
  const [formData] = useContext(HomeContext);

  const baremeAideTable = {
    1: { bleu: 22461, jaune: 27343, violet: 38184, rose: 38185 },
    2: { bleu: 32967, jaune: 40130, violet: 56130, rose: 56131 },
    3: { bleu: 39591, jaune: 48197, violet: 67585, rose: 67586 },
    4: { bleu: 46226, jaune: 56277, violet: 79041, rose: 79042 },
    5: { bleu: 52886, jaune: 64380, violet: 90496, rose: 90497 },
    6: { bleu: 59536, jaune: 72477, violet: 101951, rose: 101952 },
    7: { bleu: 66186, jaune: 80574, violet: 113406, rose: 113407 },
    8: { bleu: 72836, jaune: 88671, violet: 124861, rose: 124862 },
    9: { bleu: 79486, jaune: 96768, violet: 136316, rose: 136317 },
    10: { bleu: 86136, jaune: 104865, violet: 147771, rose: 147772 },
  };

  const baremeAideTableProvince = {
    1: { bleu: 17009, jaune: 21805, violet: 30549, rose: 30550 },
    2: { bleu: 24875, jaune: 31889, violet: 44907, rose: 44908 },
    3: { bleu: 29917, jaune: 38349, violet: 54071, rose: 54072 },
    4: { bleu: 34948, jaune: 44802, violet: 63235, rose: 63236 },
    5: { bleu: 40002, jaune: 51281, violet: 72400, rose: 72401 },
    6: { bleu: 45047, jaune: 57743, violet: 81565, rose: 81566 },
    7: { bleu: 50092, jaune: 64205, violet: 90730, rose: 90731 },
    8: { bleu: 55137, jaune: 70667, violet: 99895, rose: 99896 },
    9: { bleu: 60182, jaune: 77129, violet: 109060, rose: 109061 },
    10: { bleu: 65227, jaune: 83591, violet: 118225, rose: 118226 },
  };

  const revenuFiscal = formData.revenuFiscal;
  const numberOfOccupants = formData.numberOfOccupants;
  const factureElectricite = formData.factureElectricite;
  const typeChauffage = formData.sourceType;
  const productionECS = formData.energieType;
  const factureSecondaire = formData.factureSecondaire;
  const departement = formData.departement;
  const altitude = formData.altitude;

  formData.consommationElectrique = factureElectricite / 0.17;

  const getAideColor = () => {
    // Choix du tableau de barème en fonction de la localisation
    const baremeAideTableToUse =
      formData.provinceOuIdf === "Ile de France"
        ? baremeAideTable
        : baremeAideTableProvince;

    const aides = baremeAideTableToUse[numberOfOccupants] || {};

    if (revenuFiscal < aides.bleu) {
      return "bleu";
    } else if (revenuFiscal < aides.jaune) {
      return "jaune";
    } else if (revenuFiscal < aides.violet) {
      return "violet";
    } else {
      return "rose";
    }
  };

  formData.aideColor = getAideColor(numberOfOccupants, revenuFiscal);
  const colorScheme = {
    bleu: "blue",
    jaune: "yellow",
    violet: "purple",
    rose: "pink",
  };

  const result = getAideColor();

  const calculateDepenseECS = () => {
    if (formData.energieType === "Chaudiere") {
      return formData.factureSecondaire * 0.3;
    } else if (formData.sourceType === "Électricité") {
      return formData.factureElectricite * 0.3;
    }
    return formData.factureElectricite * 0.6;
  };

  function calculateDepenseChauffage() {
    if (formData.sourceType === "Électricité") {
      return formData.factureElectricite * 0.6;
    } else if (formData.energieType === "Chaudiere") {
      return formData.factureSecondaire * 0.7;
    } else {
      return formData.factureSecondaire;
    }
  }

  // Exemple d'utilisation de la fonction :
  formData.depenseChauffage = calculateDepenseChauffage(formData);

  formData.depenseECS = calculateDepenseECS();
  formData.depenseMenager =
    formData.factureElectricite +
    formData.factureSecondaire -
    formData.depenseChauffage -
    formData.depenseECS;

  const deptZoneMapping = {
    1: "G",
    2: "D",
    3: "E",
    4: "E",
    5: "G",
    6: "A",
    7: "D",
    8: "G",
    9: "C",
    10: "G",
    11: "C",
    12: "E",
    13: "C",
    14: "D",
    15: "E",
    16: "C",
    17: "C",
    18: "D",
    19: "E",
    21: "G",
    22: "B",
    23: "E",
    24: "C",
    25: "H",
    26: "D",
    27: "D",
    28: "D",
    29: "B",
    30: "C",
    31: "C",
    32: "C",
    33: "B",
    34: "C",
    35: "C",
    36: "D",
    37: "D",
    38: "G",
    39: "G",
    40: "C",
    41: "D",
    42: "G",
    43: "E",
    44: "C",
    45: "D",
    46: "D",
    47: "C",
    48: "E",
    49: "D",
    50: "B",
    51: "G",
    52: "H",
    53: "D",
    54: "I",
    55: "H",
    56: "B",
    57: "I",
    58: "G",
    59: "F",
    60: "D",
    61: "D",
    62: "F",
    63: "E",
    64: "C",
    65: "C",
    66: "C",
    67: "I",
    68: "I",
    69: "G",
    70: "H",
    71: "G",
    72: "D",
    73: "G",
    74: "G",
    75: "D",
    76: "D",
    77: "D",
    78: "D",
    79: "D",
    80: "F",
    81: "C",
    82: "C",
    83: "C",
    84: "D",
    85: "C",
    86: "D",
    87: "E",
    88: "I",
    89: "G",
    90: "I",
    91: "D",
    92: "D",
    93: "D",
    94: "D",
    95: "D",
  };

  const baseTempTable = {
    "0 à 200m": {
      A: -2,
      B: -4,
      C: -5,
      D: -7,
      E: -8,
      F: -9,
      G: -10,
      H: -12,
      I: -15,
    },
    "201 à 400m": {
      A: -4,
      B: -5,
      C: -6,
      D: -8,
      E: -9,
      F: -10,
      G: -11,
      H: -13,
      I: -15,
    },
    "401 à 600m": {
      A: -6,
      B: -6,
      C: -7,
      D: -9,
      E: -11,
      F: -11,
      G: -13,
      H: -15,
      I: -19,
    },
    "601 à 800m": {
      A: -8,
      B: -7,
      C: -8,
      D: -11,
      E: -13,
      F: -12,
      G: -14,
      H: -17,
      I: -21,
    },
    "801 à 1000m": {
      A: -10,
      B: -8,
      C: -9,
      D: -13,
      E: -15,
      F: -13,
      G: -17,
      H: -19,
      I: -23,
    },
    "1001 à 1200m": {
      A: -12,
      B: -9,
      C: -10,
      D: -14,
      E: -17,
      G: -19,
      H: -21,
      I: -24,
    },
    "1201 à 1400m": {
      A: -14,
      B: -10,
      C: -11,
      D: -15,
      E: -19,
      G: -21,
      H: -23,
      I: -25,
    },
    "1401 à 1600m": {
      A: -14,
      B: -10,
      C: -11,
      D: -15,
      E: -19,
      G: -21,
      H: -23,
      I: -25,
    },
    "1601 à 1800m": {
      A: -18,
      C: -13,
      E: -23,
      G: -24,
    },
    "1801 à 2000m": {
      A: -20,
      C: -14,
      E: -25,
      G: -25,
    },
    "2001 à 2200m": {
      C: -15,
      E: -27,
      G: -29,
    },
  };

  const getBaseTemperature = () => {
    const zone = deptZoneMapping[formData.departement];
    const altitudeRange = formData.altitude;

    if (baseTempTable[altitudeRange] && baseTempTable[altitudeRange][zone]) {
      return baseTempTable[altitudeRange][zone];
    } else {
      console.error(
        "Altitude range not found or does not exist in baseTempTable"
      );
      return null;
    }
  };

  formData.baseTemperature = getBaseTemperature();

  const orientationInclinaisonMatrix = {
    Sud: {
      "0°": 0.86,
      "30°": 1,
      "45°": 0.98,
      "60°": 0.93,
    },
    "Sud-Est": {
      "0°": 0.86,
      "30°": 0.93,
      "45°": 0.94,
      "60°": 0.87,
    },
    "Sud-Ouest": {
      "0°": 0.86,
      "30°": 1,
      "45°": 0.98,
      "60°": 0.93,
    },
    Ouest: {
      "0°": 0.86,
      "30°": 0.8,
      "45°": 0.74,
      "60°": 0.67,
    },
    Est: {
      "0°": 0.86,
      "30°": 0.8,
      "45°": 0.74,
      "60°": 0.67,
    },
  };

  const regionMultiplierTable = {
    "Diagonale Bretagne / Haute savoie": 1.15,
    "Nord Est": 1,
    "Sud est": 1.45,
    "Sud Ouest": 1.25,
  };

  const getValueOrientation = () => {
    const orientation = formData.orientationToiture;
    const inclination = formData.inclinaisonToiture;
    if (!orientationInclinaisonMatrix[orientation]) {
      console.error(`Orientation '${orientation}' not found in the matrix.`);
      return null;
    }
    if (!orientationInclinaisonMatrix[orientation][inclination]) {
      console.error(
        `Inclination '${inclination}' not found for orientation '${orientation}'.`
      );
      return null;
    }
    const coefficient = orientationInclinaisonMatrix[orientation][inclination];
    return coefficient;
  };

  const getValueRegion = () => {
    const regionValue = formData.region;
    if (regionValue === "Diagonale Bretagne / Haute savoie") {
      return 1.15;
    } else if (regionValue === "Nord Est") {
      return 1;
    } else if (regionValue === "Sud est") {
      return 1.45;
    } else {
      return 1.25;
    }
  };

  const valueOrientation = getValueOrientation();
  const valueRegion = getValueRegion();

  formData.solarCoefficient = null;
  if (valueOrientation !== null && valueRegion !== null) {
    formData.solarCoefficient = valueOrientation * valueRegion;
  }

  const getT17 = () => {
    const constructionYear = formData.constructionYear;
    switch (constructionYear) {
      case "avant 1975":
        return 1.6;
      case "RT 2005":
        return 0.9;
      case "RT 2012":
        return 0.75;
      case "avant 1995":
        return 1.3;
      case "RT 2000":
        return 1.1;
      default:
        console.error(`Unknown construction year label: ${constructionYear}`);
        return null;
    }
  };

  const calculateBesoinPuissanceChauffage = () => {
    const surfaceAuSol = formData.surfaceAuSol;
    const hauteurSousPlafond = formData.hauteurSousPlafond;
    const temperature = formData.temperature;
    const temperatureAdjustment = temperature + formData.baseTemperature * -1;

    const tableurT17 = getT17();
    if (tableurT17 === null) {
      return null;
    }
    const result =
      ((surfaceAuSol * hauteurSousPlafond * temperatureAdjustment) / 1000) *
      tableurT17;
    return result < 11 ? 11 : result;
  };
  formData.besoinPuissanceChauffage = calculateBesoinPuissanceChauffage();
  if (formData.besoinPuissanceChauffage !== null) {
  } else {
  }

  const getTypeDePompeAChaleur = () => {
    const chauffageType = formData.chauffageType;

    switch (chauffageType) {
      case "Mixte":
        return "Haute temperature/kit bi zone";
      case "Radiateur classique":
        return "Basse temperature";
      case "Au sol":
        return "Basse temperature";
      case "Radiateur fonte":
        return "Haute temperature";
      default:
        console.error(`Unknown chauffageType label: ${chauffageType}`);
        return null;
    }
  };
  formData.typeDePompeAChaleur = getTypeDePompeAChaleur();

  const feuil2Table = [
    { B: 10.2, D: 2250 },
    { B: 11.9, D: 2625 },
    { B: 13.6, D: 3000 },
    { B: 15.3, D: 3375 },
    { B: 17.0, D: 3750 },
    { B: 18.7, D: 4125 },
    { B: 20.4, D: 4500 },
    { B: 22.1, D: 4875 },
    { B: 23.8, D: 5250 },
    { B: 25.5, D: 5625 },
    { B: 27.2, D: 6000 },
    { B: 28.9, D: 6375 },
    { B: 30.6, D: 6750 },
    { B: 32.3, D: 7125 },
    { B: 34.0, D: 7500 },
    { B: 35.7, D: 7875 },
    { B: 37.4, D: 8250 },
    { B: 39.1, D: 8625 },
    { B: 40.8, D: 9000 },
  ];

  const calculateInstallationMaximum = (surfaceHabitable) => {
    let found = false;
    let installationMaximum = null;

    for (let i = 0; i < feuil2Table.length; i++) {
      if (
        surfaceHabitable >= feuil2Table[i].B &&
        (i === feuil2Table.length - 1 ||
          surfaceHabitable < feuil2Table[i + 1].B)
      ) {
        found = true;
        installationMaximum = feuil2Table[i].D / 375;
        break;
      }
    }

    if (!found) {
      console.error(
        `Surface habitable ${surfaceHabitable} not found in the lookup table.`
      );
    }

    return installationMaximum;
  };

  formData.installationMaximum = calculateInstallationMaximum(
    formData.superficieToiture
  );
  if (formData.installationMaximum !== null) {
  } else {
  }

  return (
    <>
      <Box
        display={"flex"}
        color={"white"}
        w={"650px"}
        justifyContent={"center"}
        bgColor={"#15af97"}
        borderRadius={"25px"}
      >
        <Heading size={"lg"}>VOS DETAILS FOYERS</Heading>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        // alignItems="center"
        p={5}
        bg="gray.100"
        borderRadius="lg"
        boxShadow="md"
      >
        <Badge
          colorScheme={colorScheme[formData.aideColor]}
          p={2}
          fontSize="1.2em"
          borderRadius="lg"
        >
          {`BAREME AIDE: ${formData.aideColor.toUpperCase()}`}
        </Badge>
        <Text fontSize="lg" mt={3}>
          {`Consommation électrique: ${formData.consommationElectrique.toFixed(
            2
          )} kWh`}
        </Text>
        <Text fontSize="lg" mt={3}>
          {`Dépense Chauffage: ${formData.depenseChauffage.toFixed(2)} €`}
        </Text>
        <Text fontSize="lg" mt={3}>
          {`Dépense ECS: ${formData.depenseECS.toFixed(2)} €`}
        </Text>
        <Text fontSize="lg" mt={3}>
          {`Dépense Ménager: ${formData.depenseMenager.toFixed(2)} €`}
        </Text>
        <Text fontSize="lg" mt={3}>
          {`Température de Base: ${formData.baseTemperature}°C`}
        </Text>
        <Text fontSize="lg" mt={3}>
          {`Coefficient solaire : ${formData.solarCoefficient}°C`}
        </Text>
        <Text fontSize="lg" mt={3}>
          {`Besoin puissance chauffage (Kwatt) : ${formData.besoinPuissanceChauffage}`}
        </Text>
        <Text fontSize="lg" mt={3}>
          {`Type de Pompe à chaleur : ${formData.typeDePompeAChaleur}`}
        </Text>
        <Text fontSize="lg" mt={3}>
          {`Installation maximum (nb de panneaux) : ${formData.installationMaximum}`}
        </Text>
      </Box>{" "}
    </>
  );
};

export default DetailFoyer;
