import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import PageHeading from "../components/layouts/PageHeading";
import ProgressBar from "../components/ProgressBar";
import ClientEnergetique from "../components/forms/ClientEnergetique";
import MultiStepForm3 from "../components/forms/MultiStepForm3";


const formSections = [
  "Renseignements du client",
  "Bilan Énergétique",
  "Solutions Énergétique",
  "Économies Énergétique",
];

const PartTwo = () => {
  const navigate = useNavigate();
  const currentSection = 2;

  const handleFormSubmit = () => {
    navigate("/client-economies");
    window.scrollTo(0, 0);
  };

  return (
    <Box width="full">
      <Box position="sticky" h={"140px"} top={0} bg="white" zIndex={1}>
        {" "}
        <ProgressBar sections={formSections} currentSection={currentSection} />
      </Box>
      <MultiStepForm3 onSubmit={handleFormSubmit} />
    </Box>
  );
};

export default PartTwo;
