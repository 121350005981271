import React from "react";
import { Box, Image, Flex, useRadio, Text, Center } from "@chakra-ui/react";

const RadioCard = ({iconName, icon, ...props}) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const isPicto7 = iconName === 'Chauffe-eau solaire';

  return (
    <Box as="label">
      <input {...input} />
      <Flex
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        width={['100px', '100px', '190px', '270px']}
        h="80px"
        padding="10px"
        gap="17px"
        borderRadius="20px"
        _checked={{
          bg: "white",
          borderColor: "blue.500",
          boxShadow: "0 0 0 3px rgba(66, 153, 225, 0.6)",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        alignItems="center"
        justifyContent="space-between"
        onClick={() => props.onChange(props.value)}
      >
        <Text
          fontSize={["10px", "10px", "10px", "14px"]}
          fontWeight="510"
          color="#8C8C8C"
          textAlign="left"
          flex="1"
          textTransform="uppercase" 
        >
          {props.children}
        </Text>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="18px"
          w={["56px", "56px", "56px", "86px"]}
          h={["50px", "50px", "60px", "70px"]}
          bgColor="#F7F9FE"
          ml="auto"
        >
         <Image 
            src={icon} 
            boxSize={isPicto7 ? "none" : "26px"} // Changez la taille uniquement pour Picto7
            // Si vous voulez garder le même boxSize mais seulement changer le width:
            style={isPicto7 ? { width: "55px" } : {}}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default RadioCard;
