import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import StepOne from './Step3/StepOne';

const MultiStepForm3 = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const handleNext = () => {
      navigate("/client-economies"); // Naviguez après la dernière étape
  };

  const handleBack = () => {
    navigate("/client-audit"); // Naviguez après la dernière étape
};

  return (
    <StepOne onNext={handleNext} onBack={handleBack} />
  );
};

export default MultiStepForm3;
