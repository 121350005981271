import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import StepOne from './Step4/StepOne';
import StepTwo from './Step4/StepTwo';
import StepThree from './Step4/StepThree';

const MultiStepForm4 = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    if (currentStep === 2) {
      window.open("https://homese.icoll.fr/presale-app", "_blank");
    } else {
      setCurrentStep(prev => prev + 1); // Avance à l'étape suivante
    }
  };
  

  const handleBack = () => {
    if (currentStep === 0) {
      navigate("/client-energetique"); // Naviguer vers "/client-energetique" lorsque vous êtes à la première étape
    } else if (currentStep > 0) {
      setCurrentStep(prev => prev - 1); // Retour à l'étape précédente si pas la première
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <StepOne onNext={handleNext} onBack={handleBack} />;
      case 1:
        return <StepTwo onNext={handleNext} onBack={handleBack} />;
      case 2:
        return <StepThree onNext={handleNext} onBack={handleBack} />;
      default:
        return <div>Étape inconnue</div>;
    }
  };

  return <div>{renderStep()}</div>;
};

export default MultiStepForm4;
