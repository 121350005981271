import React, { useContext } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Flex,
  Image,
  useToast,
} from "@chakra-ui/react";
import { HomeContext } from "../context/HomeContext";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import book from "../assets/book.pdf";
import IconCardXXL from "../components/layouts/IconCardXXL";
import Picto3 from "./picto/Picto3.png";

const Manuals = ({ onNext, onBack }) => {
  const [formData, setFormData, defaultFormData] = useContext(HomeContext);

  const navigate = useNavigate();

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = book;
    link.setAttribute("download", "Nom_du_fichier_téléchargé"); // Ou le nom du fichier que vous voulez
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box width="full" px={20} py={10}>
      <Box cursor="pointer" onClick={() => navigate("/")}>
        <Image src={logo} w="150px" alt="Home Solution Logo" />
      </Box>
      <Flex mt={'30px'}
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        borderColor={"#F4F4F4"}
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        justifyContent={"center"}
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"row"}>
          <Box mr={"20px"} flex="1">
            <Box
              display={"flex"}
              w={"289px"}
              mb={"20px"}
              h={"418px"}
              flexDirection={"column"}
              alignItems={"center"}
              borderRadius={"20px"}
              padding={"10px, 10px, 20px, 10px"}
              borderWidth={"1px"}
            >
              <IconCardXXL icon={Picto3}></IconCardXXL>
              <Text
                mt={"20px"}
                fontSize={"25px"}
                fontWeight="510"
                textTransform="uppercase" // Makes text uppercase
              >
                BOOK HOME 2024
              </Text>
              <Text
                fontWeight="510"
                mt={"10px"}
                fontSize={"20px"}
                mb={"35px"}
                color={"#8C8C8C"}
                textAlign="left" // Aligns text to the left
              >
                Telecharger le book
              </Text>
              <Button
                w="238px"
                bgColor="#30C9FF"
                color="#FCFDFF"
                borderRadius="8px"
                padding="8px 16px"
                onClick={handleDownload}
              >
                TELECHARGER
              </Button>
            </Box>
          </Box>
          <Box flex="1" mr={"20px"}>
            <Box
              display={"flex"}
              w={"289px"}
              mb={"20px"}
              h={"418px"}
              flexDirection={"column"}
              alignItems={"center"}
              borderRadius={"20px"}
              padding={"10px, 10px, 20px, 10px"}
              borderWidth={"1px"}
            >
              <IconCardXXL icon={Picto3}></IconCardXXL>
              <Text
                mt={"20px"}
                fontSize={"25px"}
                fontWeight="510"
                textTransform="uppercase"
              >
                PANNEAUX PHOTO
              </Text>
              <Text
                fontWeight="510"
                mt={"10px"}
                fontSize={"20px"}
                mb={"35px"}
                color={"#8C8C8C"}
                textAlign="left"
              >
                Déscriptifs PV
              </Text>
              <Button
                w="238px"
                bgColor="#30C9FF"
                color="#FCFDFF"
                borderRadius="8px"
                padding="8px 16px"
                onClick={() => navigate("/panneaux")}
              >
                NAVIGUER
              </Button>
            </Box>
          </Box>
          <Box flex="1" mr={"20px"}>
            <Box
              display={"flex"}
              w={"289px"}
              mb={"20px"}
              h={"418px"}
              flexDirection={"column"}
              alignItems={"center"}
              borderRadius={"20px"}
              padding={"10px, 10px, 20px, 10px"}
              borderWidth={"1px"}
            >
              <IconCardXXL icon={Picto3}></IconCardXXL>
              <Text
                mt={"20px"}
                fontSize={"25px"}
                fontWeight="510"
                textTransform="uppercase" // Makes text uppercase
              >
                PAC
              </Text>
              <Text
                fontWeight="510"
                mt={"10px"}
                fontSize={"20px"}
                mb={"35px"}
                color={"#8C8C8C"}
                textAlign="left" // Aligns text to the left
              >
                Déscriptifs PAC
              </Text>
              <Button
                w="238px"
                bgColor="#30C9FF"
                color="#FCFDFF"
                borderRadius="8px"
                padding="8px 16px"
                onClick={() => navigate("/pac")}
              >
                NAVIGUER
              </Button>
            </Box>
          </Box>
          <Box flex="1">
            <Box
              display={"flex"}
              w={"289px"}
              mb={"20px"}
              h={"418px"}
              flexDirection={"column"}
              alignItems={"center"}
              borderRadius={"20px"}
              padding={"10px, 10px, 20px, 10px"}
              borderWidth={"1px"}
            >
              <IconCardXXL icon={Picto3}></IconCardXXL>
              <Text
                mt={"20px"}
                fontSize={"25px"}
                fontWeight="510"
                textTransform="uppercase" // Makes text uppercase
              >
                BALLON THERMO
              </Text>
              <Text
                fontWeight="510"
                mt={"10px"}
                fontSize={"20px"}
                mb={"35px"}
                color={"#8C8C8C"}
                textAlign="left" // Aligns text to the left
              >
                Déscriptifs ballon
              </Text>
              <Button
                w="238px"
                bgColor="#30C9FF"
                color="#FCFDFF"
                borderRadius="8px"
                padding="8px 16px"
                onClick={() => navigate("/ballon")}
              >
                NAVIGUER
              </Button>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Manuals;

// import React from "react";
// import {
//   Box,
//   Text,
//   Button,
//   SimpleGrid,
//   Image,
//   Heading,
// } from "@chakra-ui/react";
// import logo from "../assets/logo.png";
// import book from "../assets/book.pdf";
// import { useNavigate } from "react-router-dom";
// import panorecomb from "../assets/panorecomfb425_fiche_tech.pdf";
// import aristonNimbus90 from "../assets/Ariston NIMBUS NET. 90 et 110s.pdf";
// import aristonNimbus120 from "../assets/ariston nimbus 120S ET 150S.pdf";
// import atlanticAirEau from "../assets/atlantic air eau .pdf";
// import atlanticDuo from "../assets/atlantic duo .pdf";
// import dainkinAirEau from "../assets/daikin air eau .pdf";
// import iq8se from "../assets/IQ8SE-DS-V1-MC4-FR-EU-2022-11-18_0.pdf";
// import Picto3 from "./picto/Picto3.png";

// const manuals = [
//   { id: 1, name: "Book Home 2024", file: book, icon: Picto3 },
//   { id: 2, name: "Panneaux Recom 425Wc", file: panorecomb, icon: Picto3 },
//   {
//     id: 3,
//     name: "Ariston Nimbus 90 et 110",
//     file: aristonNimbus90,
//     icon: Picto3,
//   },
//   {
//     id: 4,
//     name: "Ariston Nimbus 120 et 150",
//     file: aristonNimbus120,
//     icon: Picto3,
//   },
//   { id: 5, name: "Pac Air Eau Atlantic", file: atlanticAirEau, icon: Picto3 },
//   { id: 6, name: "Pac Air Eau Atlantic Duo", file: atlanticDuo, icon: Picto3 },
//   { id: 7, name: "Pac Air Eau Daikin", file: dainkinAirEau, icon: Picto3 },
//   { id: 8, name: "Omduleurs Enphase IQ8", file: iq8se, icon: Picto3 },
// ];

// const Manuals = () => {
//   const handleDownload = (file, name) => {
//     const link = document.createElement("a");
//     link.href = file;
//     link.setAttribute("download", name); // Utilisez le nom du manuel pour le téléchargement
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   const navigate = useNavigate();

//   return (
//     <Box width="full" px={20}>
//       <Box mt={"20px"} display={"flex"} flexDirection={"row"}>
//       <Box cursor="pointer" onClick={() => navigate('/')}>
//       <Image
//         src={logo}
//         w="150px"
//         alt="Home Solution Logo"
//       />
//     </Box>
//         <Heading mt={"50px"} ml={"300px"}>
//           Plaquettes Produits
//         </Heading>
//       </Box>

//       <Box
//         p={5}
//         display={"flex"}
//         flexDirection={"column"}
//         alignItems={"center"}
//       >
//         <Text mb={7}>
//           Téléchargez les manuels des produits pour plus d'informations
//           techniques et d'utilisation.
//         </Text>
//         <SimpleGrid columns={4} spacing={10}>
//           {manuals.map((manual) => (
//             <Box
//               key={manual.id}
//               borderWidth="1px"
//               borderRadius="20px"
//               p="10px"
//               textAlign="center"
//             >
//               <Image
//                 src={manual.icon}
//                 alt={`Icon for ${manual.name}`}
//                 boxSize="120px"
//                 m="auto"
//               />
//               <Text
//                 mt="20px"
//                 fontSize="18px"
//                 fontWeight="510"
//                 textTransform="uppercase"
//               >
//                 {manual.name}
//               </Text>
//               <Button
//                 mt="10px"
//                 w="200px"
//                 bgColor="#30C9FF"
//                 color="#FCFDFF"
//                 borderRadius="8px"
//                 padding="8px 16px"
//                 onClick={() => handleDownload(manual.file, manual.name)}
//               >
//                 Télécharger
//               </Button>
//             </Box>
//           ))}
//         </SimpleGrid>
//       </Box>
//     </Box>
//   );
// };

// export default Manuals;
