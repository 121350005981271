import React from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";

const ProgressBar = ({ sections, currentSection }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const handleLogoClick = () => {
    onOpen();
  };

  const handleNavigateHome = () => {
    // Save data if needed
    navigate("/");
  };

  const handleSectionClick = (sectionIndex) => {
    const paths = [
      "/client-info",
      "/client-audit",
      "/client-energetique",
      "/client-economies",
    ];
    const path = paths[sectionIndex];
    navigate(path);
  };

  return (
    <>
      <Flex
        direction="row"
        justify="space-between"
        align="center"
        width="full"
        px="7%"
        pb={4}
      >
        <Box mt={"20px"} onClick={handleLogoClick}>
          <Image
            src={logo}
            w={["100px", "100px", "130px", "150px"]}
            alt="Home Solution Logo"
            cursor="pointer"
          />
        </Box>
        <Flex direction="row" align="center" flex="1" justify="center">
          {sections.map((section, index) => (
            <Box
              key={section}
              as="button"
              onClick={() => handleSectionClick(index)}
              width={["180px", "180px", "214px", "214px"]}
              height="62px"
              padding="5px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              border="none"
              outline="none"
              _hover={{
                background:
                  "linear-gradient(180deg, rgba(155, 155, 155, 0) 0%, #3CBDFE 200%)",
                cursor: "pointer",
              }}
              bg={
                index === currentSection
                  ? "linear-gradient(180deg, rgba(155, 155, 155, 0) 0%, #3CBDFE 200%)"
                  : "none"
              }
            >
              <Text
                color={index === currentSection ? "black" : "gray.500"}
                fontWeight="490"
                fontSize="16px"
                bg="transparent"
              >
                {section}
              </Text>
            </Box>
          ))}
        </Flex>
      </Flex>

      {/* AlertDialog for confirmation */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Quitter le formulaire
            </AlertDialogHeader>

            <AlertDialogBody>
              Êtes-vous sûr de vouloir quitter le formulaire en cours ? Les
              données seront sauvegardées.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                bgColor="#30C9FF"
                color="white"
                borderRadius="8px"
                padding="8px 16px"
                ref={cancelRef}
                onClick={onClose}
              >
                Annuler
              </Button>
              <Button colorScheme="red" onClick={handleNavigateHome} ml={3}>
                Quitter
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ProgressBar;
